
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { ConceptModel, LocationModel, LocationsStatusAggregator } from '@/models';
import { ConceptsService, ProvidersService } from '@/services';

import RestaurantPosStatus from '@/components/buttons/RestaurantPosStatus.vue';
import SynchronizeBtn from '@/components/buttons/SynchronizeBtn.vue';
import ConceptStatus from '@/components/ConceptStatus.vue';
import { Location, Resource, ResourceType } from '@/interfaces';
import { QueryLocations } from '@/store/interfaces';

const conceptsStore = namespace('concepts')
const resourcesStore = namespace('resources')
const locationsStore = namespace('locations')

@Component({ components: { SynchronizeBtn, RestaurantPosStatus, ConceptStatus } })
export default class StatusPage extends Vue {
  problemFilters: string[] = [];
  locationsStatusAggregator: LocationsStatusAggregator | null = null;
  selectedRegion: number[] | null = null;
  locationsInRegions: number[] | null = null

  @resourcesStore.State
  readonly resources!: Resource[];

  @conceptsStore.State
  conceptsForUser!: ConceptModel[];

  @locationsStore.State
  readonly locations!: Location[];

  @locationsStore.Action
  getLocations!: (filter?: QueryLocations) => Promise<Location[]>;

  @Watch('conceptsForUser')
  onConceptForUserchanged(): void {
    this.loadData();
  }

  @Watch('selectedRegion')
  async onRegionChange(): Promise<void> {
    const filterObject: any = {}
    if (this.selectedRegion) {
      filterObject.regions = this.selectedRegion
    }
    await this.getLocations(filterObject)
    this.locationsInRegions = this.locations.map(item => item.id)
  }

  mounted(): void {
    this.getResources('region')
    this.getLocations()
    this.conceptsForUser?.length && this.loadData();
  }

  @resourcesStore.Action
  getResources!: (type: ResourceType) => Resource[];

  async loadData(): Promise<void> {
    const allRestaurantsStatus = await ConceptsService.allRestaurantsStatus();
    this.locationsStatusAggregator = allRestaurantsStatus ? new LocationsStatusAggregator(this.conceptsForUser, allRestaurantsStatus) : null;
  }
}
